import { memo } from 'react';
import styled from 'styled-components';
import DownloadMobileAppButton from './DownloadMobileAppButton';
import PopUp, { PopUpContent, PopUpHeading, PopUpImage, PopUpSubheading } from './PopUp';

const PopUpRenterAppDownload = ({ onClose, onDownloadButtonClick, androidLink, iOSLink }) => (
  <PopUp onClose={onClose}>
    <StyledPopUpContent onClose={onClose}>
      <ImageWrapper>
        <PopUpImage
          alt="renter app popup image"
          height="228"
          width="290"
          priority
          src="/images/popups/renter-app-popup-phone.png"
        />
      </ImageWrapper>
      <Heading>
        Get the best out <br />
        of Boatsetter
      </Heading>
      <Subheading>
        Book faster with
        <br /> App exclusive features
      </Subheading>
      <DownloadMobileAppButton androidLink={androidLink} iOSLink={iOSLink} onClick={onDownloadButtonClick} />
      <Waves />
    </StyledPopUpContent>
  </PopUp>
);

const ImageWrapper = styled.div`
  height: 228px;
  width: 290px;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
`;

export default memo(PopUpRenterAppDownload);

const StyledPopUpContent = styled(PopUpContent)`
  background-color: #0a4195;
`;

const Heading = styled(PopUpHeading)`
  color: #ffffff;
`;

const Subheading = styled(PopUpSubheading)`
  color: #bde3fd;
`;

const WavesComponent = (props) => (
  <svg width={104} height={177} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.818 124.529c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 124.529c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.364-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 124.529c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.962-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 124.529c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.112-1.367v-2.374c1.825 0 3.524.744 4.785 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.374a4.214 4.214 0 0 0-3.109 1.364c-1.264 1.357-2.965 2.104-4.792 2.104ZM.818 134.923c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 134.923c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 134.923c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.962-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 134.923c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.112-1.366v-2.375c1.825 0 3.524.744 4.785 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.375a4.213 4.213 0 0 0-3.109 1.363c-1.264 1.357-2.965 2.104-4.792 2.104ZM.818 145.21c-1.821 0-3.518-.742-4.777-2.089l-.091-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.259 1.347-2.956 2.089-4.777 2.089-1.827 0-3.527-.746-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.215 4.215 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.212 4.212 0 0 0 3.11-1.365c1.266-1.358 2.966-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 145.21c-1.821 0-3.518-.742-4.778-2.089l-.09-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.347-2.956 2.089-4.777 2.089-1.828 0-3.528-.746-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.215 4.215 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.212 4.212 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 145.21c-1.82 0-3.517-.742-4.777-2.089l-.09-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.347-2.956 2.089-4.777 2.089-1.827 0-3.528-.746-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.214 4.214 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.213 4.213 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 145.21c-1.82 0-3.518-.742-4.777-2.089l-.09-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.347-2.957 2.089-4.778 2.089-1.827 0-3.527-.746-4.788-2.101a4.216 4.216 0 0 0-3.112-1.366v-2.375c1.825 0 3.524.745 4.785 2.098a4.215 4.215 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.212 4.212 0 0 0 3.111-1.365c1.265-1.358 2.965-2.103 4.79-2.103v2.375a4.213 4.213 0 0 0-3.109 1.363c-1.264 1.358-2.965 2.104-4.792 2.104ZM.818 155.496c-1.821 0-3.518-.742-4.777-2.09l-.091-.106a4.209 4.209 0 0 0-3.045-1.294 4.21 4.21 0 0 0-3.058 1.301l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.097a4.211 4.211 0 0 0 3.115 1.37 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.356 2.963-2.103 4.79-2.103 1.834 0 3.535.747 4.797 2.103l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.374a4.218 4.218 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.105-4.791 2.105Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 155.496c-1.821 0-3.518-.742-4.778-2.09l-.09-.106a4.21 4.21 0 0 0-3.045-1.294 4.21 4.21 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.097a4.211 4.211 0 0 0 3.115 1.37 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.356 2.963-2.103 4.79-2.103 1.834 0 3.535.747 4.797 2.103l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.363-1.264 1.358-2.964 2.105-4.791 2.105Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 155.496c-1.82 0-3.517-.742-4.777-2.09l-.09-.106a4.209 4.209 0 0 0-3.045-1.294 4.21 4.21 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.097a4.211 4.211 0 0 0 3.115 1.37 4.207 4.207 0 0 0 3.044-1.295l.079-.093c1.262-1.356 2.962-2.103 4.79-2.103 1.834 0 3.535.747 4.797 2.103l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374a4.218 4.218 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.105-4.791 2.105Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 155.496c-1.82 0-3.518-.742-4.777-2.09l-.09-.106a4.209 4.209 0 0 0-3.045-1.294 4.21 4.21 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.112-1.367v-2.374c1.825 0 3.524.744 4.785 2.097a4.211 4.211 0 0 0 3.115 1.37 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.356 2.963-2.103 4.79-2.103 1.835 0 3.535.747 4.797 2.103l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.374a4.217 4.217 0 0 0-3.109 1.363c-1.264 1.358-2.965 2.105-4.792 2.105ZM.818 165.781a6.48 6.48 0 0 1-4.777-2.089l-.091-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099a6.48 6.48 0 0 1-4.777 2.089c-1.827 0-3.527-.746-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.211 4.211 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.209 4.209 0 0 0 3.11-1.365c1.266-1.358 2.966-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 165.781a6.48 6.48 0 0 1-4.778-2.089l-.09-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099a6.48 6.48 0 0 1-4.777 2.089c-1.828 0-3.528-.746-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.211 4.211 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.209 4.209 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 165.781a6.48 6.48 0 0 1-4.777-2.089l-.09-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099a6.48 6.48 0 0 1-4.777 2.089c-1.828 0-3.528-.746-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.211 4.211 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.962-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.209 4.209 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 165.781a6.48 6.48 0 0 1-4.777-2.089l-.09-.106a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099a6.48 6.48 0 0 1-4.778 2.089c-1.827 0-3.527-.746-4.788-2.101a4.216 4.216 0 0 0-3.112-1.366v-2.375c1.825 0 3.524.745 4.785 2.098a4.211 4.211 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.209 4.209 0 0 0 3.111-1.365c1.265-1.358 2.965-2.103 4.79-2.103v2.375a4.213 4.213 0 0 0-3.109 1.363c-1.264 1.358-2.965 2.104-4.792 2.104ZM.818 176.069c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 176.069c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 176.069c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.962-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 176.069c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.112-1.366v-2.375c1.825 0 3.524.744 4.785 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.375a4.213 4.213 0 0 0-3.109 1.363c-1.264 1.357-2.965 2.104-4.792 2.104ZM.818 114.133c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 114.133c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.364-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 114.133c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.962-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 114.133c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.112-1.367v-2.374c1.825 0 3.524.744 4.785 2.098a4.214 4.214 0 0 0 3.115 1.369 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.374a4.214 4.214 0 0 0-3.109 1.364c-1.264 1.357-2.965 2.104-4.792 2.104ZM.818 103.738c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.097a4.212 4.212 0 0 0 3.115 1.37 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 103.738c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.097a4.212 4.212 0 0 0 3.115 1.37 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.364-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 103.738c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.827 0-3.528-.747-4.788-2.101a4.217 4.217 0 0 0-3.113-1.367v-2.374c1.825 0 3.525.744 4.786 2.097a4.212 4.212 0 0 0 3.115 1.37 4.206 4.206 0 0 0 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.364-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 103.738c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.213 4.213 0 0 0-3.058 1.301l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.101a4.217 4.217 0 0 0-3.112-1.367v-2.374c1.825 0 3.524.744 4.785 2.097a4.212 4.212 0 0 0 3.115 1.37 4.207 4.207 0 0 0 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.374a4.214 4.214 0 0 0-3.109 1.364c-1.264 1.357-2.965 2.104-4.792 2.104ZM.818 93.343c-1.821 0-3.518-.741-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366V87.5c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103A4.205 4.205 0 0 0 .818 90.97a4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.966-2.103 4.79-2.103v2.375a4.215 4.215 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 93.343c-1.821 0-3.518-.741-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366V87.5c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 93.343c-1.82 0-3.517-.741-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.827 0-3.528-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366V87.5c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 93.343c-1.82 0-3.518-.741-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.102a4.216 4.216 0 0 0-3.112-1.366V87.5c1.825 0 3.524.745 4.785 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.111-1.365c1.265-1.358 2.965-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.358-2.965 2.104-4.792 2.104ZM.818 82.947c-1.821 0-3.518-.741-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.966-2.103 4.79-2.103v2.375a4.215 4.215 0 0 0-3.109 1.363c-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 82.947c-1.821 0-3.518-.741-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.524.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.962-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.034 1.286 4.211 4.211 0 0 0 3.112-1.365c1.265-1.358 2.964-2.103 4.79-2.103v2.375a4.215 4.215 0 0 0-3.11 1.363c-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 82.947c-1.82 0-3.517-.741-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.827 0-3.528-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 82.947c-1.82 0-3.518-.741-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.102a4.216 4.216 0 0 0-3.112-1.366v-2.375c1.825 0 3.524.745 4.785 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.111-1.365c1.265-1.358 2.965-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.358-2.965 2.104-4.792 2.104ZM.818 72.552c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.259 1.347-2.956 2.089-4.777 2.089-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.367V66.71c1.825 0 3.525.744 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.103 4.79-2.103 1.834 0 3.535.748 4.797 2.103l.088.103a4.205 4.205 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 72.552c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.26 1.347-2.956 2.089-4.777 2.089-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.367V66.71c1.825 0 3.524.744 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.962-2.103 4.79-2.103 1.834 0 3.535.748 4.797 2.103l.088.103a4.205 4.205 0 0 0 3.034 1.285 4.211 4.211 0 0 0 3.112-1.364c1.265-1.359 2.964-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.11 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 72.552c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.26 1.347-2.956 2.089-4.777 2.089-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.367V66.71c1.825 0 3.525.744 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.962-2.103 4.79-2.103 1.834 0 3.535.748 4.797 2.103l.088.103a4.206 4.206 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 72.552c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.26 1.347-2.957 2.089-4.778 2.089-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.112-1.367V66.71c1.825 0 3.524.744 4.785 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.103 4.79-2.103 1.835 0 3.535.748 4.797 2.103l.088.103a4.206 4.206 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.374a4.214 4.214 0 0 0-3.109 1.364c-1.264 1.357-2.965 2.104-4.792 2.104ZM.818 62.156c-1.821 0-3.518-.741-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.259 1.348-2.956 2.09-4.777 2.09-1.827 0-3.527-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.966-2.103 4.79-2.103v2.375a4.215 4.215 0 0 0-3.109 1.363C4.345 61.41 2.645 62.156.818 62.156Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 62.156c-1.821 0-3.518-.741-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.828 0-3.528-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 62.156c-1.82 0-3.517-.741-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.956 2.09-4.777 2.09-1.827 0-3.528-.747-4.788-2.102a4.216 4.216 0 0 0-3.113-1.366v-2.375c1.825 0 3.525.745 4.786 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.296l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.205 4.205 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.11-1.365c1.266-1.358 2.965-2.103 4.79-2.103v2.375c-1.185 0-2.289.484-3.109 1.363-1.264 1.358-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 62.156c-1.82 0-3.518-.741-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.302l-.085.099c-1.26 1.348-2.957 2.09-4.778 2.09-1.827 0-3.527-.747-4.788-2.102a4.216 4.216 0 0 0-3.112-1.366v-2.375c1.825 0 3.524.745 4.785 2.098a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.296l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.286 4.211 4.211 0 0 0 3.111-1.365c1.265-1.358 2.965-2.103 4.79-2.103v2.375a4.214 4.214 0 0 0-3.109 1.363c-1.264 1.358-2.965 2.104-4.792 2.104ZM.818 51.762c-1.821 0-3.518-.742-4.777-2.09l-.091-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.259 1.347-2.956 2.089-4.777 2.089-1.827 0-3.527-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.367V45.92c1.825 0 3.525.744 4.786 2.097a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.285 4.21 4.21 0 0 0 3.11-1.364c1.266-1.359 2.966-2.103 4.79-2.103v2.374a4.215 4.215 0 0 0-3.109 1.364c-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.42 51.762c-1.821 0-3.518-.742-4.778-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.26 1.347-2.956 2.089-4.777 2.089-1.828 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.367V45.92c1.825 0 3.525.744 4.786 2.097a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.285 4.21 4.21 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.364-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.02 51.762c-1.82 0-3.517-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295 4.21 4.21 0 0 0-3.058 1.301l-.085.1c-1.26 1.347-2.956 2.089-4.777 2.089-1.827 0-3.528-.747-4.788-2.101a4.216 4.216 0 0 0-3.113-1.367V45.92c1.825 0 3.525.744 4.786 2.097a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.044-1.295l.079-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.834 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.285 4.211 4.211 0 0 0 3.11-1.364c1.266-1.359 2.965-2.103 4.79-2.103v2.374c-1.185 0-2.289.485-3.109 1.364-1.264 1.357-2.964 2.104-4.791 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.622 51.762c-1.82 0-3.518-.742-4.777-2.09l-.09-.105a4.21 4.21 0 0 0-3.045-1.295c-1.165 0-2.246.46-3.058 1.301l-.085.1c-1.26 1.347-2.957 2.089-4.778 2.089-1.827 0-3.527-.747-4.788-2.101a4.215 4.215 0 0 0-3.112-1.367V45.92c1.825 0 3.524.744 4.785 2.097a4.212 4.212 0 0 0 3.115 1.37c1.155 0 2.233-.46 3.045-1.295l.078-.093c1.262-1.355 2.963-2.102 4.79-2.102 1.835 0 3.535.747 4.797 2.102l.088.103a4.206 4.206 0 0 0 3.035 1.285 4.21 4.21 0 0 0 3.111-1.364c1.265-1.359 2.965-2.103 4.79-2.103v2.374a4.214 4.214 0 0 0-3.109 1.364c-1.264 1.357-2.965 2.104-4.792 2.104Z"
      fill="#fff"
      fillOpacity={0.1}
    />
  </svg>
);

const Waves = styled(WavesComponent)`
  fill: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: 0;
  top: 0;
`;
