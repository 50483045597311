import styled, { keyframes } from 'styled-components';
import Image from 'next/image';

const PopUp = ({ children, onClose, ...other }) => (
  <Container {...other}>
    <Backdrop onClick={onClose} />
    {children}
  </Container>
);

export default PopUp;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(120%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PopUpContent = styled(({ onClose, children, ...other }) => (
  <div {...other}>
    {children}
    {onClose && <CloseButton data-testid="components-popup-close-button" onClick={onClose} />}
  </div>
))`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 196px 0 24px 0;
  border-radius: 16px 16px 0 0;
  text-align: center;
  animation: 500ms ${slideIn} cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const PopUpHeading = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.2px;
`;

export const PopUpSubheading = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 10px 0 20px 0;
`;

export const PopUpImage = styled(Image)`
  height: 228px;
`;

const Container = styled.div`
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2px);
`;

const CloseButton = styled((props) => (
  <div {...props}>
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y={1.414} width={2} height={14} rx={1} transform="rotate(-45 0 1.414)" fill="#5E696A" />
      <rect x={1.414} y={11.314} width={2} height={14} rx={1} transform="rotate(-135 1.414 11.314)" fill="#5E696A" />
    </svg>
  </div>
))`
  background-color: #f5f7f9;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 15px;
`;
