import styled from 'styled-components';
import { getMaxQueryWidth, mediaMSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useEffect, useState } from 'react';

const CopyTitle = () => <h2>Browse, book, boat</h2>;

const SubCopy = ({ isSmall }) => (
  <h3>Boat rentals, Captain-led trips, {isSmall && <br />}& on-the-water experiences.</h3>
);

const Hero = () => {
  const isMobileScreenQuery = useMediaQuery(getMaxQueryWidth(640));
  const renderMobile = isMobileScreenQuery;
  const isSmallScreenQuery = useMediaQuery(getMaxQueryWidth(600));
  const [heroBackImage, setHeroBackImage] = useState('hero-full-040725.png');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (isSmallScreenQuery) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [isSmallScreenQuery]);

  const heroPath = '/images/homepage/hero/';
  const heroImage = `${heroPath}${heroBackImage}`;

  useEffect(() => {
    if (renderMobile) {
      setHeroBackImage('hero-mobile-040725.png');
    } else {
      setHeroBackImage('hero-full-040725.png');
    }
  }, [renderMobile]);

  return (
    <HeroWrapper data-testid="homepage-hero-wrapper">
      <BgImageWrapper>
        <Image
          priority
          src={heroImage}
          alt=""
          data-testid="homepage-hero-image"
          fill
          sizes="(min-width: 640px) 1140px, 100vw"
          style={{
            objectFit: 'cover',
          }}
        />
      </BgImageWrapper>
      <div className="text" data-testid="homepage-hero-text">
        <CopyTitle />
        <SubCopy isSmall={isSmallScreen} />
      </div>
    </HeroWrapper>
  );
};

export default Hero;

const HeroWrapper = styled.div`
  display: flex;
  min-height: 575px;
  position: relative;
  margin-bottom: -160px;
  justify-content: center;

  ${mediaMSmMax} {
    min-height: 428px;
    margin-bottom: -80px;
  }

  h3 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;

    ${mediaMSmMax} {
      line-height: 130%;
      font-size: 18px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 56px;
    z-index: 1;

    ${mediaMSmMax} {
      font-size: 32px;
    }
  }

  .text {
    color: #fff;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 125px;
    padding: 0 16px;

    ${mediaMSmMax} {
      margin-top: 75px;
    }
  }
`;

const BgImageWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  position: absolute;
  height: 575px;

  ${mediaMSmMax} {
    height: 428px;
  }
`;
