import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaLgMax, mediaSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import { experiencesData } from 'components/homepage/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useBreakpoint } from '../BreakpointProvider';

const MobileComponent = ({ data }) => {
  const breakpoints = useBreakpoint();
  const isSmallMobile = breakpoints.sm;
  const isMediumMobile = breakpoints.md;
  const isLargeMobile = breakpoints.lg;
  const [numCells, setNumCells] = useState(1.4);

  useEffect(() => {
    if (isSmallMobile) {
      setNumCells(1.4);
    } else if (isMediumMobile) {
      setNumCells(2.3);
    } else if (isLargeMobile) {
      setNumCells(3.4);
    }
  }, [isSmallMobile]);
  const mobileList = data[0].concat(data[1]);
  return (
    <Swiper slidesPerView={numCells} initialSlide={0} className="carouselMobile" loop>
      {mobileList.map((item, i) => (
        <SwiperSlide key={`${item.title}-${i}`}>
          <a href={item.link}>
            <div className="carouselItem">
              <Image
                src={item.imageJPG}
                alt={item.title}
                fill
                sizes="(max-width: 768px) 70vw, 50vw"
                style={{
                  objectPosition: item.mobileObjectPosition,
                  objectFit: 'cover',
                }}
              />
              <h3>{item.title}</h3>
              <div className="gradient" />
            </div>
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const DesktopComponent = ({ data }) => {
  const slide1 = data[0];
  const slide2 = data[1];

  return (
    <>
      <div>
        <Swiper
          loop
          draggable={false}
          slidesPerView={1}
          className="carouselFlickity"
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: '.prevArrow',
            nextEl: '.nextArrow',
          }}
        >
          <SwiperSlide>
            <div className="grid">
              {slide1.map((item, i) => (
                <a
                  href={item.link}
                  key={item.title}
                  className={[i < 2 ? 'carouselTop' : 'carouselBottom', 'place'].join(' ')}
                  style={{ gridArea: item.grid }}
                  data-testid="homepage-experiences-slide1-experience-box"
                >
                  <Image
                    src={item.imageJPG}
                    alt={item.title}
                    fill
                    sizes="100vw"
                    style={{
                      objectPosition: item.objectPosition,
                    }}
                  />

                  <h3>{item.title}</h3>
                  <div className="gradient" />
                </a>
              ))}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="grid">
              {slide2.map((item, i) => (
                <a
                  href={item.link}
                  key={item.title}
                  className={[i < 2 ? 'carouselTop' : 'carouselBottom', 'place'].join(' ')}
                  style={{ gridArea: item.grid }}
                  data-testid="homepage-experiences-slide2-experience-box"
                >
                  <Image
                    src={item.imageJPG}
                    quality={50}
                    alt={item.title}
                    fill
                    sizes="100vw"
                    style={{
                      objectPosition: item.objectPosition,
                    }}
                  />
                  <h3>{item.title}</h3>
                  <div className="gradient" />
                </a>
              ))}
              <div className="nav" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="nav">
        <img
          src="/images/homepage/carousel-left.svg"
          loading="lazy"
          className="prevArrow"
          alt="left arrow"
          data-testid="homepage-experiences-left-arrow"
        />
        <img
          src="/images/homepage/carousel-left.svg"
          loading="lazy"
          alt="right arrow"
          className="right nextArrow"
          data-testid="homepage-experiences-right-arrow"
        />
      </div>
    </>
  );
};

const Experiences = () => {
  const data = experiencesData;

  const [ref] = useState(null);

  useEffect(() => {
    if (ref) {
      ref.once('ready', () => {
        setTimeout(() => {
          ref.select(0);
          ref.resize();
        }, 300);
      });
    }
  }, [ref]);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.lg;

  return (
    <FindNextWrapper data-testid="homepage-experiences-wrapper">
      <img
        className="waves"
        src="/images/homepage/experiences/yellow-waves-032825.svg"
        alt="wave decoration"
        loading="lazy"
      />
      <div className="cap container">
        <h2>Top boating categories</h2>
        {smallScreen ? <MobileComponent data={data} /> : <DesktopComponent data={data} />}
      </div>
    </FindNextWrapper>
  );
};

export default Experiences;

const FindNextWrapper = styled.div`
  background-color: #fff6e7;
  margin-top: 200px;
  margin-bottom: 169px;
  position: relative;

  @media ${mediaSmMax} {
    margin-top: 100px;
    margin-bottom: 116px;
  }

  .waves {
    max-height: 242px;
    position: absolute;
    top: calc(50% - 124px);
    height: 100%;
    transform: translateX(-65%);

    @media ${mediaSmMax} {
      transform: translateX(-84%);
    }
  }

  .grid {
    display: grid !important;
    z-index: 32;
    margin-right: 1000px;
    grid-template-columns: 20px 233px 221px 166px 166px 156px;
    grid-template-rows: 280px 200px;
    gap: 20px;
    grid-template-areas:
      'heading heading pontoon yachts yachts yachts'
      '. cruising sailing fishing fishing nav';
  }

  .container {
    transform: translateY(50px);

    @media ${mediaLgMax} {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0 !important;
    }

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.3s ease;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55.76%, rgba(0, 0, 0, 0.3) 100%);
    }

    .gradient:hover {
      opacity: 1;
    }

    .carouselFlickity {
      .slick-list {
        overflow: initial;
        margin: 0 -800px;
      }

      .slick-slide > div {
        margin: 0 800px;
      }

      .place {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
      }

      .carouselTop {
        height: 286px;

        img {
          height: 286px;
        }
      }

      .carouselBottom {
        height: 200px;

        img {
          height: 200px;
        }
      }

      .carouselTop,
      .carouselBottom {
        .carouselItem {
          width: 100%;
          height: fit-content;
          margin-right: 250px;

          @media ${mediaSmMax} {
            padding-right: 10px;
          }
        }

        img {
          border-radius: 10px;
          position: relative;
          object-fit: cover;

          transition: all 500ms ease-in-out;

          &.long {
            width: 100%;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }

          .gradient {
            opacity: 1;
          }
        }
      }

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        transition: opacity 500ms ease-in-out;
        color: white;
        z-index: 2;
        position: absolute;
        left: 20px;
        bottom: 10px;
      }
    }

    .carouselMobile {
      outline: none;
      width: 100%;

      .slick-list {
        overflow: initial;
      }

      .carouselItem {
        height: 321px;
        position: relative;
        width: 250px;
        margin-right: 25px;
        border-radius: 10px;
        overflow: hidden;

        &.long {
          width: 450px;
        }

        img {
          height: 321px;
          position: relative;
          border-radius: 10px;
          //width: 90%;
        }

        h3 {
          font-weight: bold;
          transition: opacity 500ms ease-in-out;
          color: white;
          z-index: 2;
          position: absolute;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 20px;
          left: 20px;
          bottom: 0;
        }
      }
    }
    h2 {
      top: 70px;
      max-width: 290px;
      position: absolute;
      color: #17233c;
      grid-area: heading;

      font-weight: bold;
      font-size: 32px;
      line-height: 38px;

      @media ${mediaSmMax} {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
        padding-left: 0 !important;
      }
      @media ${mediaLgMax} {
        position: unset;

        padding-bottom: 20px;
        top: unset;
        padding-left: 40px;
      }
    }

    .nav {
      grid-area: nav;
      margin-left: auto;
      position: absolute;
      right: 10px;
      bottom: 144px;
      z-index: 12;

      img {
        cursor: pointer;
        background: white;
        padding: 16px 19px;
        border-radius: 50%;

        &.right {
          margin-left: 20px;
          transform: rotateZ(180deg);
        }
      }
    }
  }
`;
