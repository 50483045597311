import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mediaMdMin } from 'helpers/breakpoints';
import cc from 'classcat';
import { useBreakpoint } from '../BreakpointProvider';

const FadeInSection = ({
  children,
  isVisibleProp = false,
  isSlow = false,
  isRight = false,
  isLeft = false,
  isSmallerMargin = false,
  rootMarginProp = '150px 150px 150px 150px',
  isSmallDistance = false,
}) => {
  const [isVisible, setVisible] = useState(isVisibleProp);
  const domRef = useRef();
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  useEffect(() => {
    if (!domRef.current) return undefined;
    if (typeof IntersectionObserver === 'undefined') return undefined;
    let isMounted = true; // note mutable flag

    const observer = new IntersectionObserver(
      async (entries) => {
        entries.forEach((entry) => {
          if (isMounted) setVisible(entry.isIntersecting);
          if (entry.isIntersecting) {
            if (domRef.current) observer.unobserve(domRef.current);
          }
        });
      },
      {
        rootMargin: rootMarginProp || (isSmallerMargin ? '0px 0px 0px 0px' : '150px 150px 150px 150px'),
      }
    );
    observer.observe(domRef.current);
    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
      isMounted = false;
    };
  }, [smallScreen, domRef]);

  if (smallScreen) {
    return children;
  }

  return (
    <FadeInWrapper
      className={cc([
        {
          'is-visible': isVisible || isVisibleProp,
          slow: isSlow,
          right: isRight,
          left: isLeft,
          small: isSmallDistance,
        },
      ])}
      ref={domRef}
    >
      {children}
    </FadeInWrapper>
  );
};

const FadeInWrapper = styled.div`
  @media ${mediaMdMin} {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;

    &.right {
      transform: translateY(0px);
      transform: translateX(150px);
    }

    &.right.small {
      transform: translateX(0);
      transform: translateY(150px);
    }

    &.left {
      transform: translateY(0px);
      transform: translateX(-150px);
    }

    &.slow {
      transition: opacity 1000ms ease-out, transform 1200ms ease-out, visibility 1200ms ease-out;
    }

    &.is-visible {
      opacity: 1;
      transform: none;
      visibility: visible;
    }
  }
`;

export default FadeInSection;
