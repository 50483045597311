export const iOS = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const getMobileOperatingSystem = () => {
  const thisWindow = window;
  const userAgent = navigator.userAgent || navigator.vendor || thisWindow.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !thisWindow.MSStream) {
    return 'iOS';
  }
};

export const isMobileUserAgent = (userAgent) => /iPhone|iPad|iPod|Android/i.test(userAgent);
