import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaLgMax, mediaMdMax, mediaSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useBreakpoint } from '../BreakpointProvider';

const locationList = [
  {
    name: 'Miami',
    image: '/images/homepage/explore/Miami.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Miami,%20FL,%20USA&area=&page=1&ne_lat=25.85578602396197&ne_lng=-80.13217904641093&sw_lat=25.7090419531335&sw_lng=-80.31860792381018&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Los Angeles',
    image: '/images/homepage/explore/LosAngeles.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Los%20Angeles,%20CA,%20USA&area=&page=1&ne_lat=34.33730604252446&ne_lng=-118.155289077463&sw_lat=33.70365193147634&sw_lng=-118.6681761264879&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Fort Lauderdale',
    image: '/images/homepage/explore/FortLauderdale.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Fort%20Lauderdale,%20FL,%20USA&area=&page=1&ne_lat=26.211927993713&ne_lng=-80.09590395442405&sw_lat=26.07050306928547&sw_lng=-80.20370500560662&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Chicago',
    image: '/images/homepage/explore/Chicago-040225.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Chicago,%20IL,%20USA&area=&page=1&ne_lat=42.02313509578566&ne_lng=-87.52366097503476&sw_lat=41.6442860457925&sw_lng=-87.94009700774616&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Tampa',
    image: '/images/homepage/explore/Tampa.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Tampa,%20FL,%20USA&area=&page=1&ne_lat=28.1714770496866&ne_lng=-82.25974103831388&sw_lat=27.81264902802819&sw_lng=-82.64882001467284&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Seattle',
    image: '/images/homepage/explore/Seattle.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Seattle,%20WA,%20USA&area=&page=1&ne_lat=47.73527996850646&ne_lng=-122.2198220380426&sw_lat=47.48214998086826&sw_lng=-122.4597739026988&zoom_level=11&map_toggle=off',
  },
  {
    name: 'New York',
    image: '/images/homepage/explore/NewYork.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=New%20York,%20NY,%20USA&area=&page=1&ne_lat=40.91770499010228&ne_lng=-73.70016897460856&sw_lat=40.4765780006847&sw_lng=-74.25884295708299&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Fort Myers',
    image: '/images/homepage/explore/FortMyers.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Fort%20Myers,%20FL,%20USA&area=&page=1&ne_lat=26.68914103529231&ne_lng=-81.751142978373&sw_lat=26.54776210839773&sw_lng=-81.91367202886191&zoom_level=11&map_toggle=off',
  },
  {
    name: 'Dallas',
    image: '/images/homepage/explore/Dallas.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Dallas,%20TX,%20USA&area=&page=1&ne_lat=33.02379205649777&ne_lng=-96.46371007987953&sw_lat=32.61321602232911&sw_lng=-97.00048203416956&zoom_level=10&map_toggle=off',
  },
  {
    name: 'West Palm Beach',
    image: '/images/homepage/explore/WestPalmBeach.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=West%20Palm%20Beach,%20FL,%20USA&area=&page=1&ne_lat=26.83810097547852&ne_lng=-80.04287902694949&sw_lat=26.64436605297124&sw_lng=-80.214627909545&zoom_level=10&map_toggle=off',
  },
  {
    name: 'Phoenix',
    image: '/images/homepage/explore/Phoenix.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Phoenix,%20AZ,%20USA&area=&page=1&ne_lat=33.91839108124326&ne_lng=-111.9255209204802&sw_lat=33.29026001114327&sw_lng=-112.3240650818251&zoom_level=10&map_toggle=off',
  },
  {
    name: 'Austin',
    image: '/images/homepage/explore/Austin.png',
    link: 'https://www.boatsetter.com/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=Austin,%20TX,%20USA&area=&page=1&ne_lat=30.51665192026394&ne_lng=-97.52758606022502&sw_lat=30.07557595431852&sw_lng=-97.93835399287835&zoom_level=10&map_toggle=off',
  },
];

export const Explore = () => {
  const [numPerSlide, setNumPerSlide] = useState(1.2);
  const breakpoints = useBreakpoint();
  const isXSMobile = breakpoints.xs;
  const isSmallMobile = breakpoints.sm;
  const isMdScreen = breakpoints.md;

  useEffect(() => {
    if (isSmallMobile) {
      setNumPerSlide(1.2);
    } else if (isMdScreen) {
      setNumPerSlide(3);
    } else {
      setNumPerSlide(4);
    }
  }, [breakpoints]);

  return (
    <ReviewWrapper data-testid="homepage-review-wrapper" className="explore">
      <div className="wrapper">
        <h2>Explore destinations by boat</h2>
        <p>Book a private boat rental, just about anywhere.</p>
        <div className="nav">
          <img
            src="/images/homepage/carousel-left.svg"
            loading="lazy"
            alt="left"
            className="prevArrowReview"
            data-testid="homepage-review-left-arrow"
          />
          <img
            src="/images/homepage/carousel-left.svg"
            loading="lazy"
            alt="right arrow"
            className="right nextArrowReview"
            data-testid="homepage-review-right-arrow"
          />
        </div>

        <div className="container">
          <Swiper
            modules={[Pagination, Navigation]}
            draggable
            spaceBetween={isSmallMobile ? 20 : 50}
            navigation={{
              prevEl: '.prevArrowReview',
              nextEl: '.nextArrowReview',
            }}
            slidesPerView={numPerSlide}
          >
            {locationList.map((location, index) => (
              <SwiperSlide data-swiper-slide-index={index} key={location.name}>
                <a className="location-card" href={location.link}>
                  <Image
                    src={location.image}
                    alt={location.name}
                    width={isXSMobile ? 205 : 254}
                    height={isXSMobile ? 156 : 193}
                  />
                  <span>{location.name}</span>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </ReviewWrapper>
  );
};

const ReviewWrapper = styled.div`
  margin: 100px auto 120px;
  max-width: 1076px;
  padding: 0 20px;

  @media ${mediaMdMax} {
    margin: 120px auto;
  }

  .wrapper {
    display: grid;
    grid-template-areas:
      'heading nav'
      'subtitle nav'
      'boats boats';
    grid-template-columns: 1fr auto;

    @media ${mediaSmMax} {
      margin-left: 20px;
    }

    & > p {
      grid-area: subtitle;
      max-width: calc(100% - 104px);
      line-height: 34px;
      font-size: 20px;
      font-weight: 400;

      @media ${mediaSmMax} {
        font-size: 15px;
        line-height: 22px;
        max-width: 100%;
      }
    }

    .container {
      min-width: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      grid-area: boats;

      @media ${mediaLgMax} {
        max-width: unset;
      }

      @media ${mediaSmMax} {
        max-height: unset;
        padding-bottom: 0px;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    grid-area: heading;
    max-width: calc(100% - 104px);
    min-width: 0;

    @media ${mediaLgMax} {
      width: 100%;
      max-width: 100%;
      font-size: 24px;
      line-height: 24px;
    }
  }

  .nav {
    grid-area: nav;
    display: flex;
    align-self: center;
    min-width: 0;
    width: 105px;
    z-index: 200;

    @media ${mediaMdMax} {
      display: none;
    }

    img {
      cursor: pointer;
      background: #f5f7f9;
      padding: 16px 19px;
      border-radius: 50%;
      height: 47px;
      width: 47px;

      &.right {
        margin-left: 20px;
        transform: rotateZ(180deg);
      }
    }
  }

  .swiper {
    padding-right: 45px;
  }

  .swiper-slide {
    min-width: 0;
    display: flex;
    justify-content: space-between;
  }

  .location-card {
    display: flex;
    flex-direction: column;
    & > img {
      border-radius: 10px;
      margin-bottom: 20px;
    }
    & > span {
      font-size: 18px;
      font-weight: 600;
      line-height: 120%;
      color: #17233c;
    }
  }
`;
