import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'beautiful-react-hooks';
import { useBreakpoint } from 'components/BreakpointProvider';
import ClientOnlyPortal from 'components/ClientOnlyPortal';

const useMobilePopup = ({
  PopUpComponent,
  popUpName = '',
  showDelay = 0,
  popUpComponentProps = {},
  lazyLoad = false,
}) => {
  const [permanentlyClosed, setPermanentlyClosed] = useLocalStorage(popUpName, false);
  const [isOpen, setIsOpen] = useState(false);
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const showPopUp = showDelay >= 0 && isOpen && !permanentlyClosed && smallScreen;

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setPermanentlyClosed(true);
  }, []);

  useEffect(() => {
    if (showDelay === 0 && lazyLoad === true) {
      return;
    }

    if (showDelay >= 0) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, showDelay);

      // eslint-disable-next-line consistent-return
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [showDelay]);

  const open = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (showDelay !== 0 && lazyLoad === true) return;

    const elementById = document.getElementById('__next');
    elementById.addEventListener('scroll', open, { once: true });
    elementById.addEventListener('keydown', open, { once: true });
    elementById.addEventListener('mouseover', open, { once: true });

    // eslint-disable-next-line consistent-return
    return () => {
      elementById.removeEventListener('scroll', open);
      elementById.removeEventListener('keydown', open);
      elementById.removeEventListener('mouseover', open);
    };
  }, [showDelay]);

  return showPopUp && PopUpComponent ? (
    <ClientOnlyPortal selector="#popUpPortal">
      <PopUpComponent onClose={() => handleClose()} {...popUpComponentProps} />
    </ClientOnlyPortal>
  ) : null;
};

export default useMobilePopup;
