import React, { useRef } from 'react';

import styled from 'styled-components';
import { mediaMLgMax, mediaMSmMax, mediaMMdMax, mediaXsMax } from 'helpers/breakpoints';
import { useBreakpoint } from 'components/BreakpointProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 20px;
  ${mediaMLgMax} {
    margin: 0px;
    width: 100%;
  }
  ${mediaMSmMax} {
    width: 100%;
  }
`;

const WrappedContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0 0 0px;
  ${mediaMLgMax} {
    padding: 40px 70px;
  }
  ${mediaMSmMax} {
    padding: 20px 0;
  }
`;

const TabsContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  ${mediaMSmMax} {
    width: 90%;
    margin: 0 auto;
  }
  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: none;
    color: #0a4195;
    ${mediaMSmMax} {
      display: block;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 12px;
  border-bottom: 1px solid #dbdfe5;
  gap: 40px;
  justify-content: flex-start;
  paddding: 0;
  ${mediaMSmMax} {
    padding: 0 20px;
    overflow-x: scroll;
    scrollbehavior: smooth;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Tab = styled.button<{ $selected: boolean }>`
  font-size: 14px;
  letter-spacing: 0.14px;
  font-weight: 700;
  color: ${({ $selected }) => ($selected ? '#0a4195' : '#9A9E9F')};
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 3px solid ${({ $selected }) => ($selected ? '#0a4195' : 'transparent')};
  &:hover {
    border-bottom: 3px solid #0a4195;
    color: #0a4195;
  }
  white-space: nowrap;
  user-select: none;
  ${mediaMSmMax} {
    padding: 8px 0px;
    margin-right: 10px;
  }
  ${mediaXsMax} {
    padding: 0;
  }
`;

const LeftArrow = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  height: 100%;
  width: 60px;
  top: 0;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 15px;
  display: none;
  background: linear-gradient(to right, #fff 50%, transparent);
  ${mediaMSmMax} {
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  }
`;
const RightArrow = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  height: 100%;
  width: 60px;
  top: 0;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  padding-bottom: 10px;
  background: linear-gradient(to left, #fff 50%, transparent);
  display: none;
  @media only screen and (max-width: 550px) {
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  }
`;

const List = styled.ul<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'grid' : 'none')};
  grid-template-columns: repeat(6, minmax(150px, 1fr));
  grid-column-gap: 40px;
  ${mediaMLgMax} {
    grid-template-columns: repeat(4, minmax(150px, 250px));
    grid-column-gap: 50px;
  }
  ${mediaMMdMax} {
    grid-template-columns: repeat(4, minmax(140px, 250px));
    column-gap: 20px;
  }

  ${mediaMSmMax} {
    grid-template-columns: repeat(2, minmax(140px, 250px));
    column-gap: 20px;
    margin: 0px auto;
    padding: 0;
    width: 80%;
  }
  ${mediaXsMax} {
    width: 50%;
  }
`;

const ListItem = styled.li<{ $isVisible: boolean }>`
  && {
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    font-size: 14px;
    cursor: pointer;
    color: #17233c;
    font-weight: 400;
    line-height: 33px;
    &:hover {
      text-decoration: underline;
    }
    ${mediaMLgMax} {
      flex: 1;
      text-align: left;
      flex-basis: 17%;
    }
    & a {
      color: #17233c;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const ShowMore = styled.li`
  flex-basis: 15%;
  min-width: 150px;
  flex-grow: 1;
  font-size: 14px;
  cursor: pointer;
  color: #17233c;
  font-weight: 400;
  line-height: 33px;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`;

type Link = {
  name: string;
  url: string;
};

const destinations: Link[] = [
  { name: 'Miami, FL', url: '/boat-rentals/miami--fl--united-states' },
  { name: 'Chicago, IL', url: '/boat-rentals/chicago--il--united-states' },
  { name: 'Tampa, FL', url: '/boat-rentals/tampa--fl--united-states' },
  { name: 'New York, NY', url: '/boat-rentals/new-york--ny--united-states' },
  { name: 'Washington DC', url: '/boat-rentals/washington--dc--united-states' },
  { name: 'Los Angeles, CA', url: '/boat-rentals/los-angeles--ca--united-states' },
  { name: 'Austin, TX', url: '/boat-rentals/austin--tx--united-states' },
  { name: 'Fort Lauderdale, FL', url: '/boat-rentals/fort-lauderdale--fl--united-states' },
  { name: 'Seattle, WA', url: '/boat-rentals/seattle--wa--united-states' },
  { name: 'West Palm Beach, FL', url: '/boat-rentals/west-palm-beach--fl--united-states' },
  { name: 'Charleston, SC', url: '/boat-rentals/charleston--sc--united-states' },
  { name: 'Panama City Beach, FL', url: '/boat-rentals/panama-city-beach--fl--united-states' },
  { name: "Coeur d'Alene, ID", url: '/boat-rentals/coeur-d-alene--id--united-states' },
  { name: 'Boston, MA', url: '/boat-rentals/boston--ma--united-states' },
  { name: 'Orlando, FL', url: '/boat-rentals/orlando--fl--united-states' },
  { name: 'Key West, FL', url: '/boat-rentals/key-west--fl--united-states' },
  { name: 'Destin, FL', url: '/boat-rentals/destin--fl--united-states' },
  { name: 'Fort Myers, FL', url: '/boat-rentals/fort-myers--fl--united-states' },
  { name: 'Naples, FL', url: '/boat-rentals/naples--fl--united-states' },
  { name: 'Islamorada, FL', url: '/boat-rentals/islamorada--fl--united-states' },
  { name: 'Marina Del Rey, CA', url: '/boat-rentals/marina-del-rey--ca--united-states' },
  { name: 'San Diego, CA', url: '/boat-rentals/san-diego--ca--united-states' },
  { name: 'Sarasota, FL', url: '/boat-rentals/sarasota--fl--united-states' },
  { name: 'Detroit, MI', url: '/boat-rentals/detroit--mi--united-states' },
  { name: 'Florida Keys, FL', url: '/boat-rentals/florida-keys--fl--united-states' },
  { name: 'Clearwater, FL', url: '/boat-rentals/clearwater--fl--united-states' },
  { name: 'Newport Beach, CA', url: '/boat-rentals/newport-beach--ca--united-states' },
  { name: 'Cape Coral, FL', url: '/boat-rentals/cape-coral--fl--united-states' },
  { name: 'Dallas, TX', url: '/boat-rentals/dallas--tx--united-states' },
  { name: 'Orange Beach, AL', url: '/boat-rentals/orange-beach--al--united-states' },
  { name: 'Jacksonville, FL', url: '/boat-rentals/jacksonville--fl--united-states' },
  { name: 'Key Largo, FL', url: '/boat-rentals/key-largo--fl--united-states' },
  { name: 'Ocean City, NJ', url: '/boat-rentals/ocean-city--nj--united-states' },
  { name: 'Pensacola, FL', url: '/boat-rentals/pensacola--fl--united-states' },
  { name: 'Vero Beach, FL', url: '/boat-rentals/vero-beach--fl--united-states' },
  { name: 'Long Island, NY', url: '/boat-rentals/long-island--ny--united-states' },
  { name: 'Tempe, AZ', url: '/boat-rentals/tempe--az--united-states' },
  { name: 'Crystal River, FL', url: '/boat-rentals/crystal-river--fl--united-states' },
  { name: 'Atlanta, GA', url: '/boat-rentals/atlanta--ga--united-states' },
  { name: 'St. Petersburg, FL', url: '/boat-rentals/st-petersburg--fl--united-states' },
  { name: 'Milwaukee, WI', url: '/boat-rentals/milwaukee--wi--united-states' },
  { name: 'Nashville, TN', url: '/boat-rentals/nashville--tn--united-states' },
  { name: 'San Francisco, CA', url: '/boat-rentals/san-francisco--ca--united-states' },
  { name: 'Annapolis, MD', url: '/boat-rentals/annapolis--md--united-states' },
  { name: 'Minnetonka, MN', url: '/boat-rentals/minnetonka--mn--united-states' },
  { name: 'Myrtle Beach, SC', url: '/boat-rentals/myrtle-beach--sc--united-states' },
  { name: 'Houston, TX', url: '/boat-rentals/houston--tx--united-states' },
  { name: 'Catalina Island, CA', url: '/boat-rentals/catalina-island--ca--united-states' },
  { name: 'Honolulu, HI', url: '/boat-rentals/honolulu--hi--united-states' },
  { name: 'Savannah, GA', url: '/boat-rentals/savannah--ga--united-states' },
  { name: 'Daytona Beach, FL', url: '/boat-rentals/daytona-beach--fl--united-states' },
];

const experiences: Link[] = [
  { name: 'Boat Rentals', url: '/boat-rentals' },
  { name: 'Fishing Charters', url: '/fishing-charters' },
  { name: 'Pontoon Boat Rentals', url: '/pontoon-boat-rentals' },
  { name: 'Yacht Rentals', url: '/yacht-rentals' },
  { name: 'Party Boat Rentals', url: '/party-boat-rentals' },
  { name: 'Sailboat Rentals', url: '/sailboat-rentals' },
  { name: 'Luxury Yacht Charters', url: '/luxury-yacht-charters' },
  { name: 'Marco Island, FL', url: '/marco-island--fl--united-states' },
];

const states: Link[] = [
  { name: 'Alabama', url: '/boat-rentals/al--united-states' },
  { name: 'Alaska', url: '/boat-rentals/ak--united-states' },
  { name: 'Arizona', url: '/boat-rentals/az--united-states' },
  { name: 'Arkansas', url: '/boat-rentals/ar--united-states' },
  { name: 'California', url: '/boat-rentals/ca--united-states' },
  { name: 'Colorado', url: '/boat-rentals/co--united-states' },
  { name: 'Connecticut', url: '/boat-rentals/ct--united-states' },
  { name: 'Delaware', url: '/boat-rentals/de--united-states' },
  { name: 'Florida', url: '/boat-rentals/fl--united-states' },
  { name: 'Georgia', url: '/boat-rentals/ga--united-states' },
  { name: 'Hawaii', url: '/boat-rentals/hi--united-states' },
  { name: 'Idaho', url: '/boat-rentals/id--united-states' },
  { name: 'Illinois', url: '/boat-rentals/il--united-states' },
  { name: 'Indiana', url: '/boat-rentals/in--united-states' },
  { name: 'Iowa', url: '/boat-rentals/ia--united-states' },
  { name: 'Kansas', url: '/boat-rentals/ks--united-states' },
  { name: 'Kentucky', url: '/boat-rentals/ky--united-states' },
  { name: 'Louisiana', url: '/boat-rentals/la--united-states' },
  { name: 'Maine', url: '/boat-rentals/me--united-states' },
  { name: 'Maryland', url: '/boat-rentals/md--united-states' },
  { name: 'Massachusetts', url: '/boat-rentals/ma--united-states' },
  { name: 'Michigan', url: '/boat-rentals/mi--united-states' },
  { name: 'Minnesota', url: '/boat-rentals/mn--united-states' },
  { name: 'Mississippi', url: '/boat-rentals/ms--united-states' },
  { name: 'Missouri', url: '/boat-rentals/mo--united-states' },
  { name: 'Montana', url: '/boat-rentals/mt--united-states' },
  { name: 'Nebraska', url: '/boat-rentals/ne--united-states' },
  { name: 'Nevada', url: '/boat-rentals/nv--united-states' },
  { name: 'New Hampshire', url: '/boat-rentals/nh--united-states' },
  { name: 'New Jersey', url: '/boat-rentals/nj--united-states' },
  { name: 'New Mexico', url: '/boat-rentals/nm--united-states' },
  { name: 'New York', url: '/boat-rentals/ny--united-states' },
  { name: 'North Carolina', url: '/boat-rentals/nc--united-states' },
  { name: 'North Dakota', url: '/boat-rentals/nd--united-states' },
  { name: 'Ohio', url: '/boat-rentals/oh--united-states' },
  { name: 'Oklahoma', url: '/boat-rentals/ok--united-states' },
  { name: 'Oregon', url: '/boat-rentals/or--united-states' },
  { name: 'Pennsylvania', url: '/boat-rentals/pa--united-states' },
  { name: 'Rhode Island', url: '/boat-rentals/ri--united-states' },
  { name: 'South Carolina', url: '/boat-rentals/sc--united-states' },
  { name: 'Tennessee', url: '/boat-rentals/tn--united-states' },
  { name: 'Texas', url: '/boat-rentals/tx--united-states' },
  { name: 'Utah', url: '/boat-rentals/ut--united-states' },
  { name: 'Vermont', url: '/boat-rentals/vt--united-states' },
  { name: 'Virginia', url: '/boat-rentals/va--united-states' },
  { name: 'Washington', url: '/boat-rentals/wa--united-states' },
  { name: 'Washington DC', url: '/boat-rentals/washington--dc--united-states' },
  { name: 'West Virginia', url: '/boat-rentals/wv--united-states' },
  { name: 'Wisconsin', url: '/boat-rentals/wi--united-states' },
  { name: 'Wyoming', url: '/boat-rentals/wy--united-states' },
];

const lakes: Link[] = [
  { name: 'Lake Tahoe, CA', url: '/boat-rentals/lake-tahoe--united-states' },
  { name: 'Lake Norman, NC', url: '/boat-rentals/lake-norman--nc--united-states' },
  { name: 'Lake Travis, TX', url: '/boat-rentals/lake-travis--tx--united-states' },
  { name: 'Lake Pleasant, AZ', url: '/boat-rentals/lake-pleasant--az--united-states' },
  { name: 'Lake Lanier, GA', url: '/boat-rentals/lake-lanier--ga--united-states' },
  { name: 'Lake Havasu, AZ', url: '/boat-rentals/lake-havasu--united-states' },
  { name: 'Lake Conroe, TX', url: '/boat-rentals/lake-conroe--tx--united-states' },
  { name: 'Lake of the Ozarks', url: '/boat-rentals/lake-of-the-ozarks--mo--united-states' },
  { name: 'Canyon Lake, TX', url: '/boat-rentals/canyon-lake--tx--united-states' },
  { name: 'Lake Wylie, SC', url: '/boat-rentals/lake-wylie--sc--united-states' },
  { name: 'Lake Anna, VA', url: '/boat-rentals/lake-anna--va--united-states' },
  { name: 'Lake Austin, TX', url: '/boat-rentals/lake-austin--tx--united-states' },
  { name: 'Lake Minnetonka, MN', url: '/boat-rentals/lake-minnetonka--mn--united-states' },
  { name: 'Lake Mead, NV', url: '/boat-rentals/lake-mead--united-states' },
  { name: 'Lake Murray, SC', url: '/boat-rentals/lake-murray--sc--united-states' },
  { name: 'Jordan Lake, NC', url: '/boat-rentals/jordan-lake--nc--united-states' },
  { name: 'Big Bear Lake, CA', url: '/boat-rentals/big-bear-lake--ca--united-states' },
  { name: 'Lewisville Lake, TX', url: '/boat-rentals/lake-lewisville--tx--united-states' },
  { name: 'Lake Powell, AZ', url: '/boat-rentals/lake-powell--united-states' },
  { name: 'Lake Geneva, WI', url: '/boat-rentals/lake-geneva--wi--united-states' },
  { name: 'Table Rock Lake, MO', url: '/boat-rentals/table-rock-lake--united-states' },
  { name: 'Lake Hartwell, GA', url: '/boat-rentals/hartwell--ga--united-states' },
  {
    name: 'Winnipesaukee, NH',
    url: '/boat-rentals/lake-winnipesaukee--nh--united-states',
  },
  { name: 'Norris Lake, TN', url: '/boat-rentals/norris-lake--tn--united-states' },
  { name: 'Lake Allatoona, GA', url: '/boat-rentals/lake-allatoona--ga--united-states' },
  { name: 'Lake Texoma, OK', url: '/motor-boat-rentals/lake-texoma--united-states' },
  { name: 'Lake Keowee, SC', url: '/boat-rentals/lake-keowee--sc--united-states' },
  { name: 'Saguaro Lake, AZ', url: '/boat-rentals/saguaro-lake--az--united-states' },
  { name: 'Lake Monroe, FL', url: '/boat-rentals/lake-monroe--fl--united-states' },
  { name: 'Bass Lake, CA', url: '/boat-rentals/bass-lake--ca--united-states' },
  { name: 'Lake Chelan, WA', url: '/boat-rentals/lake-chelan--wa--united-states' },
  { name: 'Lake Arrowhead, CA', url: '/boat-rentals/lake-arrowhead--ca--united-states' },
  { name: 'Grand Lake, CO', url: '/boat-rentals/grand-lake--co--united-states' },
  { name: 'Lake Berryessa', url: '/boat-rentals/lake-berryessa--ca--united-states' },
  { name: 'Lake Ray Hubbard, TX', url: '/boat-rentals/lake-ray-hubbard--dallas--tx--united-states' },
];

function LinkItems({
  selectedTab,
  currentTab,
  items,
  showAllDestinations,
  setShowAllDestinations,
  numberOfItems,
}: {
  selectedTab: string;
  currentTab: string;
  items: Link[];
  showAllDestinations: boolean;
  setShowAllDestinations: (showAllDestinations: boolean) => void;
  numberOfItems: number;
}) {
  return (
    <List $isVisible={selectedTab === currentTab}>
      {items.map(({ name, url }, index) => (
        <ListItem key={name} $isVisible={showAllDestinations || numberOfItems > index}>
          <a href={url}>{name}</a>
        </ListItem>
      ))}
      {!showAllDestinations && currentTab !== 'Top experiences' ? (
        <ShowMore onClick={() => setShowAllDestinations(true)}>Show more</ShowMore>
      ) : null}
    </List>
  );
}

// Component
const SEOLinks = () => {
  const tabsRef = React.useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoint();
  let numberOfItems = 7;
  if (!breakpoints.xs && !breakpoints.sm) {
    numberOfItems = 15;
  }
  if (!breakpoints.xs && !breakpoints.sm && !breakpoints.md && !breakpoints.lg) {
    numberOfItems = 23;
  }

  const [selectedTab, setSelectedTab] = React.useState('Top destinations');
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(true);

  const destRef = useRef(null);
  const expRef = useRef(null);
  const stateRef = useRef(null);
  const lakeRef = useRef(null);
  const [showAllDestinations, setShowAllDestinations] = React.useState(false);

  function manageArrows() {
    if (tabsRef.current.scrollLeft >= 10) {
      setShowLeftArrow(true);
    } else {
      setShowLeftArrow(false);
    }
    const scrollWidth = tabsRef.current.scrollWidth - tabsRef.current.clientWidth - 10;
    if (tabsRef.current.scrollLeft >= scrollWidth) {
      setShowRightArrow(false);
    } else {
      setShowRightArrow(true);
    }
  }

  const slideLeft = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft += 100;
      manageArrows();
    }
  };

  const slideRight = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft -= 100;
      manageArrows();
    }
  };

  return (
    <WrappedContainer>
      <Container>
        <TabsContainer>
          <LeftArrow onClick={slideRight} $isVisible={showLeftArrow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </LeftArrow>
          <Tabs ref={tabsRef} onScroll={() => manageArrows()}>
            <Tab
              $selected={selectedTab === 'Top destinations'}
              ref={destRef}
              onClick={() => {
                destRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
                setSelectedTab('Top destinations');
              }}
            >
              Top destinations
            </Tab>
            <Tab
              $selected={selectedTab === 'Top experiences'}
              ref={expRef}
              onClick={() => {
                expRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
                setSelectedTab('Top experiences');
              }}
            >
              Top experiences
            </Tab>
            <Tab
              $selected={selectedTab === 'States'}
              ref={stateRef}
              onClick={() => {
                tabsRef.current?.scrollBy(100, 0);
                setSelectedTab('States');
              }}
            >
              States
            </Tab>
            <Tab
              $selected={selectedTab === 'Lakes'}
              ref={lakeRef}
              onClick={() => {
                setSelectedTab('Lakes');
              }}
            >
              Lakes
            </Tab>
          </Tabs>
          <RightArrow onClick={slideLeft} $isVisible={showRightArrow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </RightArrow>
        </TabsContainer>
        <LinkItems
          selectedTab={selectedTab}
          currentTab="Top destinations"
          items={destinations}
          showAllDestinations={showAllDestinations}
          setShowAllDestinations={setShowAllDestinations}
          numberOfItems={numberOfItems}
        />
        <LinkItems
          selectedTab={selectedTab}
          currentTab="Top experiences"
          items={experiences}
          showAllDestinations={showAllDestinations}
          setShowAllDestinations={setShowAllDestinations}
          numberOfItems={numberOfItems}
        />
        <LinkItems
          selectedTab={selectedTab}
          currentTab="States"
          items={states}
          showAllDestinations={showAllDestinations}
          setShowAllDestinations={setShowAllDestinations}
          numberOfItems={numberOfItems}
        />
        <LinkItems
          selectedTab={selectedTab}
          currentTab="Lakes"
          items={lakes}
          showAllDestinations={showAllDestinations}
          setShowAllDestinations={setShowAllDestinations}
          numberOfItems={numberOfItems}
        />
      </Container>
    </WrappedContainer>
  );
};

export default SEOLinks;
