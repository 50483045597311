import { useEffect } from 'react';
import SearchInputTwo from 'components/search/v2/SearchInput';
import styled from 'styled-components';
import { mediaLgMax, mediaMdMax, mediaSmMax, mediaXsMax } from 'helpers/breakpoints';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import boatsetterPaths from 'utils/boatsetterPaths';
import {
  filterQueryStringAtom,
  resetAllFiltersAtom,
  resetDSUAtom,
  setPlaceFromSearchAtom,
} from '../search/v2/Filter/filterStore';
import { useBreakpoint } from '../BreakpointProvider';

const SearchBar = () => {
  useEffect(() => {
    const top = document.querySelector<HTMLElement>('#__next');
    top.style.position = 'fixed';
  }, []);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const filterQueryString = useAtomValue(filterQueryStringAtom);

  const router = useRouter();
  const setNearAtom = useSetAtom(setPlaceFromSearchAtom);
  const resetOtherQuery = useSetAtom(resetDSUAtom);
  const resetAllFilters = useSetAtom(resetAllFiltersAtom);

  const makeSearch = () => {
    router.push({ pathname: boatsetterPaths.search, search: `?${filterQueryString}` }, null, { shallow: true });
  };

  useEffect(() => {
    const stickyElm = document.getElementById('stickyWrapper');

    if (typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle('isSticky', e.intersectionRatio > 0.92 && e.intersectionRatio !== 1);
        },
        { threshold: [0.4, 1, 0.6, 0.8] }
      );

      observer.observe(stickyElm);
    }
  }, []);

  return (
    <StickyWrapper id="stickyWrapper" data-testid="homepage-search-bar-wrapper">
      <img src="/images/homepage/Logo.svg" loading="lazy" className="mobileLogo" alt="" />
      <SearchInputTwo
        Icon={
          <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9952 2.5H11.9855C8.05245 2.50954 4.86533 5.65871 4.87502 9.53315C4.88471 13.1595 10.7843 20.4216 11.4624 21.2328C11.598 21.4046 11.8111 21.5 12.0242 21.5C12.247 21.5 12.4505 21.4046 12.5861 21.2328C13.2545 20.4121 19.1347 13.1404 19.125 9.50452C19.1153 5.63963 15.9185 2.5 11.9952 2.5ZM6.32811 9.53315C6.31842 6.45078 8.85649 3.94098 11.9855 3.93144H11.9952C15.1145 3.93144 17.6622 6.43169 17.6719 9.51406C17.6719 11.9093 14.0004 17.1007 12.0145 19.6296C10.0286 17.1007 6.32811 11.9284 6.32811 9.53315ZM13.3708 9.51406C13.3708 10.2584 12.7508 10.8692 11.9952 10.8692C11.2396 10.8692 10.6196 10.2584 10.6196 9.51406C10.6196 8.76971 11.2396 8.15897 11.9952 8.15897C12.7508 8.15897 13.3708 8.76971 13.3708 9.51406Z"
              fill="#8E9697"
            />
          </svg>
        }
        handleRedirect={makeSearch}
        onSelect={(place) => {
          setNearAtom(place);
          resetOtherQuery();
          resetAllFilters();
        }}
        placeholder={smallScreen ? 'Enter location' : 'Where would you like to go boating?'}
      />
    </StickyWrapper>
  );
};

export default SearchBar;

const StickyWrapper = styled.div`
  width: 100%;
  height: auto;
  position: sticky;
  top: -1px;
  transition: all 0.2s linear;
  z-index: 999;

  & > img {
    display: none;
  }

  .search {
    z-index: 2;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
    border-radius: 40.5px;
    padding: 8px;
    grid-area: search;
    height: 80px;

    position: relative;
    transition: all 0.2s linear;

    @media ${mediaMdMax} {
      transform: translateY(-36%);
      height: 54px;
    }
    // transform: translateY(-50%);

    .locationInput {
      padding-left: 10px;
      min-width: 270px;
      width: 100%;
      display: flex;
      align-items: center;

      @media ${mediaMdMax} {
        padding-left: 5px;
        min-width: 170px;
      }

      @media ${mediaXsMax} {
        min-width: 100px;
      }

      input {
        font-size: 16px !important;
        line-height: 1.4;
        background-color: white;
        color: black;
        height: calc(54px - 18px);
        padding-left: 15px;
        width: 100% !important;
        @media ${mediaSmMax} {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      input::placeholder {
        opacity: 1; /* Firefox */
        color: #8e9697 !important;
        font-size: 16px !important;
        width: 100% !important;
        min-width: 136px;
      }

      div[class*='SearchFormPrefix'] {
        display: flex !important;
        @media ${mediaSmMax} {
          svg {
            display: none;
          }
        }
      }
    }

    .searchCTA {
      background: #0751c2;
      border-radius: 51px;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      padding: 14px 50px;

      svg {
        display: none;
      }

      @media ${mediaMdMax} {
        padding: 12px 18px;
        font-size: 12px;
      }

      @media ${mediaSmMax} {
        padding: 12px 12px;
        svg {
          display: block;
        }
        .btnText {
          display: none;
        }
      }
    }
  }

  &.isSticky {
    background-color: white;
    z-index: 9999;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    display: flex;
    padding: 13px;
    align-items: center;

    & > img {
      display: block;
      height: 32px;
      position: absolute;
      margin: 0 auto auto 0px;
      @media screen and (max-width: 1240px) {
        display: none;
      }
    }

    @media ${mediaLgMax} {
      display: block;
    }

    .search {
      transform: unset;
      z-index: 9999;
      margin: auto;
      height: 64px;
      max-width: 880px;
      border: 1px solid #8e9697;
      box-shadow: none;
    }

    .searchCTA {
      padding: 14px 50px;

      @media ${mediaMdMax} {
        padding: 14px 18px;
      }
      @media ${mediaSmMax} {
        padding: 15px;
      }
    }

    .search.cap {
      @media ${mediaMdMax} {
        margin: 0px !important;
      }
    }
  }
`;
