import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import useCloseOnClickOutside from 'hooks/useCloseOnClickOutside';
import styled from 'styled-components';
import { Flex, FlexColAlignCenterBoth } from 'components/primitives/flex';
import { mediaMMdMax } from 'helpers/breakpoints';
import Script from 'next/script';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useAtomValue } from 'jotai';
import boatsetterPaths from 'utils/boatsetterPaths';
import { filterQueryStringAtom } from './Filter/filterStore';

interface Props {
  placeholder?: string;
  className?: string;
  onChange?: (e: string) => void;
  onClick?: () => void;
  onSelect?: (place: google.maps.places.PlaceResult) => void;
  Icon?: ReactNode;
  handleRedirect?: (internal: boolean) => void;
}

const { GOOGLE_API } = getConfig().publicRuntimeConfig;

export default ({ placeholder, className, onChange, Icon, onClick, onSelect, handleRedirect }: Props) => {
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [searchLoaded, setSearchLoaded] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult>(undefined);

  const router = useRouter();

  const filterQueryString = useAtomValue(filterQueryStringAtom);

  const ref = useRef<HTMLInputElement>();

  const wrapperRef = useCloseOnClickOutside(suggestionsVisible, () => {
    setSuggestionsVisible(false);
    setActiveSuggestion(-1);
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    // googleMaps: IS_CLIENT ? window.google.maps : null,
    requestOptions: {
      types: ['geocode', 'establishment'],
      // @ts-ignore
      fields: ['address_components', 'geometry', 'formatted_address'],
    },
    initOnMount: false,
    debounce: 300,
  });

  const makeSearch = (internal: boolean) => {
    if (!selectedPlace && !internal) {
      if (data && data.length > 0) {
        handleSelect(data[activeSuggestion === -1 ? 0 : activeSuggestion]);
        setActiveSuggestion(-1);
      } else {
        if (ref) ref.current.focus();
        return;
      }
    }

    router.push({ pathname: boatsetterPaths.search, search: `?${filterQueryString}` }, null, { shallow: true });
  };

  const handleInput = (e) => {
    init();
    if (onChange) onChange(e);
    if (!suggestionsVisible) {
      setSuggestionsVisible(true);
    }
    setValue(e.target.value);
  };

  const handleSelect = async (selection) => {
    if (!selection) return;

    const { description, place_id } = selection;
    setValue(description, false);
    setActiveSuggestion(-1);
    const place = (await getDetails({ placeId: place_id })) as google.maps.places.PlaceResult;
    clearSuggestions();
    setSuggestionsVisible(false);
    setSelectedPlace(place);

    if (place && place.address_components) {
      if (onSelect) {
        onSelect(place);
        setShouldRedirect(true);
      }
    }
  };

  useEffect(() => {
    if (handleRedirect && shouldRedirect) {
      handleRedirect(true);
      setShouldRedirect(false);
    }
  }, [shouldRedirect]);

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className={`pac-item ${activeSuggestion === index ? 'pac-item-selected' : ''}`}
          key={place_id}
          onClick={(e) => {
            e.preventDefault();
            handleSelect(suggestion);
          }}
        >
          <span className="pac-icon pac-icon-marker" />
          <span className="pac-item-query">
            <span className="pac-matched">{main_text}</span>
          </span>
          <span className="pac-item-sub">{secondary_text}</span>
        </li>
      );
    });

  return (
    <div className="search cap">
      {searchLoaded && (
        <Script src={`https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${GOOGLE_API}`} />
      )}
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        className="locationInput"
        onMouseOver={() => {
          setSearchLoaded(true);
        }}
        onClick={() => setSearchLoaded(true)}
        data-testid="homepage-search-bar-input"
      >
        <Wrapper className={className} ref={wrapperRef}>
          <Flex>
            <IconWrapper>{Icon && Icon}</IconWrapper>
            <StyledInput
              ref={ref}
              placeholder={placeholder}
              type="text"
              autoComplete="off"
              onClick={onClick}
              value={value}
              onChange={handleInput}
              onFocus={() => {
                setSuggestionsVisible(true);
              }}
              onKeyDown={(e) => {
                init();
                if (e.key === 'ArrowDown') {
                  setActiveSuggestion(Math.min(activeSuggestion + 1, data.length));
                } else if (e.key === 'ArrowUp') {
                  setActiveSuggestion(Math.max(activeSuggestion - 1, 0));
                }
              }}
              onKeyPress={(e) => {
                init();
                if (e.key === 'Enter') {
                  handleSelect(data[activeSuggestion === -1 ? 0 : activeSuggestion]);
                  setActiveSuggestion(-1);
                }
              }}
            />
          </Flex>
          {status === 'OK' && ready && suggestionsVisible && (
            <Suggestions className="pac-container pac-logo hdpi">{renderSuggestions()}</Suggestions>
          )}
        </Wrapper>
      </div>
      <button
        type="button"
        onClick={() => makeSearch(false)}
        className="searchCTA"
        data-testid="homepage-search-bar-search-button"
      >
        <span className="btnText">Search</span>
        <Search />
        {/* <img src={'/images/homepage/search.svg'} alt="" /> */}
      </button>
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Suggestions = styled.ul`
  top: 58px !important;
  padding: 0 20px 20px 20px;
  border-radius: 18px !important;

  max-width: unset;
  min-width: unset;

  ${mediaMMdMax} {
    top: 8px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  line-height: normal;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  border: 0;

  box-sizing: border-box !important;
  display: block !important;
  flex-basis: auto !important;

  @media only screen and (min-width: 640px) {
    width: 300px !important;
  }

  &.pac-target-input:not(:-webkit-autofill) {
    animation-name: endBrowserAutofill;
  }

  &::placeholder {
    color: #fff !important;
    transition: all 0.3s ease;
  }
`;

const Search = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.353 10.6285C10.7206 10.261 11.3165 10.261 11.6841 10.6285L15.0589 14.0034C15.4265 14.3709 15.4265 14.9669 15.0589 15.3344C14.6914 15.702 14.0955 15.702 13.7279 15.3344L10.353 11.9595C9.98549 11.592 9.98549 10.9961 10.353 10.6285Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86363 11.2941C9.46261 11.2941 11.5695 9.18722 11.5695 6.58824C11.5695 3.98925 9.46261 1.88235 6.86363 1.88235C4.26464 1.88235 2.15774 3.98925 2.15774 6.58824C2.15774 9.18722 4.26464 11.2941 6.86363 11.2941ZM6.86363 13.1765C10.5022 13.1765 13.4519 10.2268 13.4519 6.58824C13.4519 2.94965 10.5022 0 6.86363 0C3.22504 0 0.275391 2.94965 0.275391 6.58824C0.275391 10.2268 3.22504 13.1765 6.86363 13.1765Z"
      fill="white"
    />
  </svg>
);

const IconWrapper = styled(FlexColAlignCenterBoth)``;
