import { useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';
import { mediaMdMax, mediaSmMax } from 'helpers/breakpoints';
import cc from 'classcat';
import { useAnimationHook } from './animationUtils';
import FadeInSection from './fadeInSection';
import BoatsetterPromiseText from '../../../../public/images/homepage/promise/boatsetter promise.svg';

export const BoatsetterPromise = ({ data }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useAnimationHook(domRef, setVisible);

  return (
    <BoatsetterPromiseWrapper className={cc(['howWorks', 'cap'])} ref={domRef}>
      <FadeInSection isSlow isVisibleProp={isVisible} isSmallerMargin>
        <div className="menu">
          <BoatsetterPromiseText />
        </div>
      </FadeInSection>
      <FadeInSection isSlow isRight isVisibleProp={isVisible} isSmallerMargin>
        <div className="info">
          {data.map((item) => (
            <div key={item.text + item.title}>
              <FadeIn transitionDuration={400} delay={0}>
                <img
                  loading="lazy"
                  width={24}
                  height={24}
                  src={item.icon}
                  alt="Search"
                  data-testid="homepage-howWorks-user-type-link-icon"
                />
              </FadeIn>

              <FadeIn transitionDuration={500} delay={0}>
                <h4 data-testid="homepage-howWorks-user-type-link-title">{item.title}</h4>
              </FadeIn>
              <FadeIn transitionDuration={800} delay={0}>
                <p data-testid="homepage-howWorks-user-type-link-text">{item.text}</p>
              </FadeIn>
            </div>
          ))}
        </div>
      </FadeInSection>
    </BoatsetterPromiseWrapper>
  );
};

const BoatsetterPromiseWrapper = styled.div`
  height: calc(250px + 150px);
  display: flex;

  @media ${mediaSmMax} {
    margin-left: 0 !important;
    margin-bottom: 60px !important;
    min-height: 750px;
    max-height: 800px;
  }

  .info {
    background: #ffffff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    position: relative;
    display: flex;
    top: 100px;
    gap: 7px;
    padding: 38px;
    left: -265px;
    @media ${mediaMdMax} {
      position: absolute;
      left: 40px;
      gap: 20px;
      top: calc(100% - 290px);
    }
    @media ${mediaSmMax} {
      position: absolute;
      padding: 30px 30px 0;
      gap: unset !important;
      left: 20px !important;
      top: 100px;
      width: calc(100% - 28px);
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
    }

    & > div {
      display: block;
      width: 210px;

      @media ${mediaMdMax} {
        width: 100%;
      }

      @media ${mediaSmMax} {
        height: auto !important;
        margin-bottom: 32px;
      }

      &:not(:last-child) {
        padding-right: 20px;
        @media ${mediaMdMax} {
          padding-right: unset;
        }
      }

      h4 {
        font-weight: bold;
        line-height: 24px;
        @media ${mediaSmMax} {
          padding-bottom: 5px;
        }
      }

      p {
        font-size: 16px;
      }

      img {
        margin-bottom: 13px;
        &.searchIcon {
          margin-bottom: 13px;
          margin-top: -3px;
          @media ${mediaSmMax} {
            margin-bottom: 6px;
          }
        }
        @media ${mediaSmMax} {
          margin-bottom: 5px;
        }
      }
    }

    @keyframes inAnimation {
      0% {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
      100% {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }

    @keyframes outAnimation {
      0% {
        opacity: 1;
        display: block;
      }
      100% {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }
  }

  .menu {
    padding: 40px;
    max-width: 370px;
    min-height: fit-content;
    background-color: #ecf7fe;
    height: 196px;

    @media ${mediaMdMax} {
      max-width: 600px;
      height: 164px;
    }
    @media ${mediaSmMax} {
      padding: 32px 20px 32px 20px;

      & > svg {
        transform: scale(0.89);
      }
    }
  }
`;
