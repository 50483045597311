import { useEffect } from 'react';

export const useAnimationHook = (domRef, setVisible) => {
  useEffect(() => {
    if (!domRef.current) return;
    if (typeof IntersectionObserver === 'undefined') return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setVisible(entry.isIntersecting);
          if (entry.isIntersecting) {
            if (domRef.current) observer.unobserve(domRef.current);
          }
        });
      },
      {
        rootMargin: '150px 150px 150px 150px',
      }
    );
    observer.observe(domRef.current);
    return () => {
      if (domRef.current) observer.unobserve(domRef.current);
    };
  }, [domRef]);
};
