import boatsetterPaths from '../../utils/boatsetterPaths';

export const howWorksData = [
  {
    title: 'Trip protection that works for you',
    text: 'Book with confidence. If bad weather disrupts your trip, you’re eligible for a refund.',
    icon: '/images/homepage/promise/boat.svg',
  },
  {
    title: 'Verified quality standards',
    text: 'Each vessel is reviewed against a checklist for safety, reliability, and cleanliness.',
    icon: '/images/homepage/promise/circle-check.svg',
  },
  {
    title: 'Safe & secure payments',
    text: 'Experience a seamless checkout process, ensuring your transaction is protected.',
    icon: '/images/homepage/promise/lock.svg',
  },
  {
    title: 'Anticipates the unexpected',
    text: 'We’ve designed coverage to handle damages, so you can focus on the adventure.',
    icon: '/images/homepage/promise/life-saver.svg',
  },
];

export const experiencesData = [
  [
    {
      title: 'Pontoon boats',
      imageJPG: '/images/homepage/experiences/Pontoons-040225.png',
      link: boatsetterPaths.pontoonSearch,
      grid: 'pontoon',
      className: 'long',
      mobileObjectPosition: '35%',
    },
    {
      title: 'Yachts rentals',
      imageJPG: '/images/homepage/experiences/Yachts-040225.png',
      link: boatsetterPaths.yachtSearch,
      grid: 'yachts',
      className: 'long',
    },
    {
      title: 'Cruising',
      link: boatsetterPaths.cruisingSearch,
      imageJPG: '/images/homepage/experiences/Cruising-040225.png',
      grid: 'cruising',
      className: 'long',
      mobileObjectPosition: '70%',
    },
    {
      title: 'Unique finds',
      imageJPG: '/images/homepage/experiences/UniqueFinds-040225.png',
      link: boatsetterPaths.uniqueFinds,
      grid: 'sailing',
      objectPosition: '40%',
      mobileObjectPosition: '0',
    },
    {
      title: 'Fishing charters',
      imageJPG: '/images/homepage/experiences/Fishing-040224.png',
      link: boatsetterPaths.fishingSearch,
      grid: 'fishing',
    },
  ],
  [
    {
      title: 'Celebrations',
      imageJPG: '/images/homepage/experiences/Celebrations-040225.png',
      link: boatsetterPaths.celebrationsSearch,
      grid: 'pontoon',
      className: 'long',
      mobileObjectPosition: '35%',
    },
    {
      title: 'Luxury yacht charters',
      imageJPG: '/images/homepage/experiences/LuxuryYachts-040225.png',
      link: boatsetterPaths.luxuryYachtSearch,
      grid: 'yachts',
      mobileObjectPosition: '25%',
    },
    {
      title: 'Water sports',
      link: boatsetterPaths.waterSportsSearch,
      imageJPG: '/images/homepage/experiences/WaterSports-040225.png',
      grid: 'cruising',
      className: 'long',
      mobileObjectPosition: '70%',
    },
    {
      title: 'Sailing',
      imageJPG: '/images/homepage/experiences/Sailing-040225.png',
      link: boatsetterPaths.sailingSearch,
      grid: 'sailing',
      objectPosition: '40%',
      mobileObjectPosition: '0',
    },
    {
      title: 'Snorkeling',
      imageJPG: '/images/homepage/experiences/Snorkeling-040225.png',
      link: boatsetterPaths.snorkelingSearch,
      grid: 'fishing',
    },
  ],
];

export const reviewsData = [
  {
    name: 'Ashlee',
    review: 'Absolutely loved the boat. It was very spacious and the crew was amazing. Will definitely book again!',
    boat: '/images/homepage/reviews/ashlee/boat.jpg',
    profile: '/images/homepage/reviews/ashlee/profile.jpg',
  },
  {
    name: 'Skylar',
    review:
      'Great boat for the price! Jiri was helpful, patient, and flexible! Also the marina was in a prime location. 10/10 would rent again!',
    boat: '/images/homepage/reviews/skylar/boat.jpg',
    profile: '/images/homepage/reviews/skylar/profile.jpg',
  },
  {
    name: 'Jennifer',
    review:
      'Amazing experience! We had such a blast and Joe was fantastic. Couldn’t recommend more! 100% will book again!',
    boat: '/images/homepage/reviews/jennifer/boat.jpg',
    profile: '/images/homepage/reviews/jennifer/profile.jpg',
  },
  {
    name: 'Michael',
    review:
      'Boat was extremely clean, very spacious and the speaker system was great. Definitely will be renting this boat again!',
    boat: '/images/homepage/reviews/michael/boat.jpg',
    profile: '/images/homepage/reviews/michael/profile.jpg',
  },
  {
    name: 'Brandon',
    review: 'Great experience. Lindsay was friendly and helpful. We had a fantastic time. Would rent again.',
    boat: '/images/homepage/reviews/brandon/boat.jpg',
    profile: '/images/homepage/reviews/brandon/profile.jpg',
  },
  {
    name: 'Capri',
    review: 'I had the most amazing time ever! I would give ten stars if I could.',
    boat: '/images/homepage/reviews/capri/boat.jpg',
    profile: '/images/homepage/reviews/capri/profile.jpg',
  },
];

export const quotesData = {
  twp: 'The next wave of the sharing economy has arrived...',
  cnbc: 'Boat owners can now offset their entire cost of boat ownership by renting their boats in as few as one to two times a month.',
  twsj: 'Boatsetter makes it easy to book an afternoon sail on a whim...',
  tc: 'Boats have even more potential than homes for the sharing economy. With a few rentals a month, most boats can pay for themselves.',
  cnn: 'Boatsetter makes it simpler for folks to spend time on the water.',
  buzz: 'Boatsetter is a one-stop shop for boat renters...and allows boat owners to offset associated ownership costs.',
};
