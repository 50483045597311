import styled from 'styled-components';
import { mediaMSmMax, mediaSmMax } from 'helpers/breakpoints';
import { useBreakpoint } from '../BreakpointProvider';

export const SpecialOffer = ({ isVisible }) => {
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  if (!isVisible) return <div />;

  return (
    <Wrapper>
      <span className="heading">
        Attending {smallScreen ? 'MIBS' : 'Miami International Boat Show'}? Enhance Your Boat Show Experience
        {!smallScreen && ' with Boatsetter'}. <a href="/miami-boat-show">Learn more</a>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #0751c2;
  line-height: 50px;
  height: 50px;
  padding: 0 15px;
  color: #ffffff;

  ${mediaMSmMax} {
    flex-direction: column;
    height: 45px;
    line-height: 45px;
  }

  a {
    color: white !important;
    font-weight: 700;
  }

  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 115%;
    margin: auto 0;
    ${mediaMSmMax} {
      font-size: 12px;
    }
  }

  .promoCode {
    color: #f4c064;
    font-weight: bold;
    margin-right: 6px;
    @media ${mediaSmMax} {
      margin-top: 3px;
    }
  }

  .dateUntil {
    font-size: 12px;
    @media ${mediaSmMax} {
      margin-top: 12px;
    }
  }
`;
