import { mediaMdMax, mediaMdMin, mediaSmMax } from 'helpers/breakpoints';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useAnimationHook } from './animationUtils';
import FadeInSection from './fadeInSection';

export const ExperiencesSummerCollection = ({ children, images }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useAnimationHook(domRef, setVisible);

  const { desktopJPG, mobileJPG } = images;

  return (
    <ExperiencesSummerCollectionWrapper
      data-testid="homepage-experiences-summer-collection-captain-box"
      className="ExperiencesSummerCollectionModule captain"
      ref={domRef}
    >
      <div className="cap">
        <FadeInSection isSlow isSmallerMargin isVisibleProp={isVisible} isRight>
          <div className="desktopWrap">
            <Image height={380} width={975} className="imgDesktop" src={desktopJPG} alt="Own a boat" />
          </div>
          <div className="imgMobile">
            <Image loading="lazy" width={285} height={380} src={mobileJPG} alt="Own a boat" />
          </div>
        </FadeInSection>

        <FadeInSection isSlow isSmallerMargin isVisibleProp={isVisible}>
          <div className="box">{children}</div>
        </FadeInSection>
      </div>
    </ExperiencesSummerCollectionWrapper>
  );
};

const ExperiencesSummerCollectionWrapper = styled.div`
  margin: 0 auto 120px auto;

  @media ${mediaSmMax} {
    height: 590px;
    margin: 105px auto 80px auto;
  }

  @media ${mediaMdMax} {
    margin-bottom: 80px;
  }

  .cap {
    @media ${mediaSmMax} {
      overflow: unset;
      display: flex;
    }
  }

  &.captain {
    .imgMobile {
      width: 100%;
      @media ${mediaMdMin} {
        display: none !important;
      }

      img {
        border-radius: 16px;
        float: right;
        position: relative;
        padding-left: 0 !important;
        @media ${mediaSmMax} {
          margin-left: auto;
          right: 0;
        }
      }
    }

    .box {
      background: #f4c064 !important;
      position: absolute;
      right: 0;
      transform: translateY(-355px);
      z-index: 2;
      padding: 40px;
      margin-left: auto;
      height: auto;
      max-width: 308px;
      width: fit-content;

      @media ${mediaSmMax} {
        right: auto;
        left: 0;
      }
      @media ${mediaSmMax} {
        transform: unset !important;
        bottom: -210px;
        min-width: 286px;
        max-width: 310px;
        max-height: 300px;
        width: calc(100% - 25px);
        padding: 35px;
      }
    }

    h2 {
      color: #154353 !important;
      font-weight: bold;
      font-size: 32px;
      line-height: 34px;

      @media ${mediaSmMax} {
        font-size: 24px;
        line-height: 29px;
      }
    }

    .desktopWrap {
      right: 0;
      left: 0 !important;
      height: 380px;
      position: relative;
      overflow: hidden;
      margin-right: 90px;

      @media ${mediaSmMax} {
        display: none;
      }

      .imgDesktop {
        float: right;
        position: relative;
        border-radius: 16px;
        @media ${mediaMdMax} {
          margin-left: 0;
          float: left !important;
          transform-origin: top left !important;
        }
        @media ${mediaSmMax} {
          display: none;
          left: unset;
        }
      }
    }

    a {
      color: #154353;
      text-decoration: underline #083170;
      font-weight: bold;
    }
    p {
      color: #154353;
      line-height: 130%;
    }
  }
`;
